const initState = {
  IsDarkMode: false,
};

export const SwitchReducer = (state = initState, action) => {
  switch (action.type) {
    case "LIGHTMODE": {
      return {
        ...state,
        IsDarkMode: false,
      };
    }
    case "DARKMODE": {
      return {
        ...state,
        IsDarkMode: true,
      };
    }
    default:
      return { ...state };
  }
};
