import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReduxWrapper from "./src/redux/reduxWrapper/ReduxWrapper";

// import "@popperjs/core/dist/umd/popper.min"
export const wrapRootElement = props => {
  return (
    <>
      <ReduxWrapper {...props} />
    </>
  );
};
